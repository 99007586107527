import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Formatter, setFormat } from '../grid-common/grid-common.formatter';
import { LegendService } from '../grid-common/legend/legend.service';
import { titleCaseFormatterText } from '../../app/common/string-utils';
import { GridViewTypeEnum, RequestTypeEnum } from '../filters';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnState } from 'ag-grid-community';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  pageSize = 50;
  toggleValue = false;

  gridToggleValue$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  gridModeValue$: BehaviorSubject<string> = new BehaviorSubject<string>('chart');
  datastoreMiddleViewportScroll$: BehaviorSubject<Event | undefined> = new BehaviorSubject<Event | undefined>(undefined);
  datastoreEastPanelVisibility$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  datastoreLoadingScreenVisibility$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  datastoreLoadingScreenText$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  datastoreTabSelectedIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);//PLS-485
  cotcorridorItem$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  cotcorridorHistoryItem$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  datastoreTabName$: BehaviorSubject<string> = new BehaviorSubject<string>('packprice');
  datastoreSelectedColumn$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  datastoreSelectedColumnPnR$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  datastoreSelectedColumnCOT$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  datastoreSelectedColumnHTA$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  datastoreShowHistory$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  datastoreExportAll$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  datastoreExcelSheet$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  datastoreRegulatoryIndicationFlag$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  datagridData$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  reimbursementComparatorContainerScroll$: BehaviorSubject<Event | undefined> = new BehaviorSubject<Event | undefined>(undefined);
  journeyDetailsFilters$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  nonSOCCOuntries$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  gridmodetotRecortCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  chartmodeflag$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  gridmodesortType$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  COTCorBrandList$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  datastoreOnLoadCompleted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showSingPostDivflag$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  hospitalReimnCountryList$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  datastoreHideColumn$: BehaviorSubject<ColumnState | undefined> = new BehaviorSubject<ColumnState | undefined>(undefined);
  datastoreSignPostValPnR$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  datastoreSignPostValPnRGermany$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  datastoreSignPostValHTAChina$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  datastoreSignPostValHTA$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  hospitalReimnCountryVal$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  isGermanyExist$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isChinaExist$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  brandFilterSelectedBrand$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  activeSectionName$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  gridmodePageNumber$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  gridmodeRecordPerPage$: BehaviorSubject<number> = new BehaviorSubject<number>(this.pageSize);
  gridmodesortColumn$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  gridmodesortColumnText$:BehaviorSubject<string>=new BehaviorSubject<string>('');
  isPrelaunchDataExistInPNR$:BehaviorSubject<boolean>=new BehaviorSubject<boolean>(false);
  isPrelaunchDataExistInCOT$:BehaviorSubject<boolean>=new BehaviorSubject<boolean>(false);
  selectedBrandPreferenceVal$:BehaviorSubject<string>=new BehaviorSubject<string>('');
  showBrandPrefSaveIcon$:BehaviorSubject<boolean>=new BehaviorSubject<boolean>(true);
  selectedSearchValue$:BehaviorSubject<string>=new BehaviorSubject<string>('');
  brandIndicationValue$:BehaviorSubject<any[]>=new BehaviorSubject<any[]>([]);
  IsBrandPrefSelectedInMainSearch$:BehaviorSubject<boolean>=new BehaviorSubject<boolean>(false);
  isAvailabilityMapLaunchDateVisible$:BehaviorSubject<boolean>=new BehaviorSubject<boolean>(false);
  isBrandPreferenceValChanged$:BehaviorSubject<boolean>=new BehaviorSubject<boolean>(false);
  isTotalValuescoreByCountriesCall$:BehaviorSubject<boolean>=new BehaviorSubject<boolean>(false);
  TotalValuescoreByCountriesdata$:BehaviorSubject<any[]>=new BehaviorSubject<any[]>([]);
  isBrandCotDataCall$:BehaviorSubject<boolean>=new BehaviorSubject<boolean>(false);
  BrandCotDatadata$:BehaviorSubject<any[]>=new BehaviorSubject<any[]>([]);
  rowData: any[] = [];


  constructor(private legendService: LegendService
    , public route: ActivatedRoute
    , private router: Router) { }


  updateSelectedBrand(brand: string) {
    this.brandFilterSelectedBrand$.next(brand);
  }

  updateGridToggle(): void {
    this.toggleValue = !this.toggleValue;
    this.gridToggleValue$.next(this.toggleValue);
  }

  setShowLoadingScreen(toggle: boolean, text: string = ''): void {
    this.datastoreLoadingScreenVisibility$.next(toggle)
    this.datastoreLoadingScreenText$.next(text)
  }
  
  pnrDataload(dataset: any[], checkdate: boolean): any {
    let isShowHistorical = this.datastoreShowHistory$.value;
    let lastPackId: number = -1;
    let lastCountryHoverTitle: string | null = null;
    let lastBrandINN: string | null = null;
    let lastPackDesc: string | null = null;

    this.rowData = [];
    let index = this.datastoreSelectedColumnPnR$.value.findIndex(x=> x === "availabilityStatus")

    //check if prelaunch data exist or not
    const hasNullOrEmptyPackDescription = dataset.some(item => {
      return item.packDescription === null || (item.packDescription && item.packDescription.trim() === '');
    });
    this.isPrelaunchDataExistInPNR$.next(hasNullOrEmptyPackDescription);
    //
    
    dataset?.forEach((row: any, idx: number) => {
      let newRow = { ...row }
      // if (checkdate) {//when export current page, date columns coming with T. ths removed this condition
      if(index != -1){
        newRow.availabilityStatus = row.packDescription === null ? 'Approved' : 'Available';
      }
      newRow.launchDate = setFormat(newRow.launchDate, Formatter.DateFormat);     
      newRow.sourceUpdateDate = setFormat(newRow.sourceUpdateDate, Formatter.DateFormat);
      newRow.priceStartDate = setFormat(newRow.priceStartDate, Formatter.DateFormat);
      newRow.priceEndDate = setFormat(newRow.priceEndDate, Formatter.DateFormat);
      newRow.reimbursedAnnotations = titleCaseFormatterText(newRow.reimbursedAnnotations);
      newRow.therapyClass = titleCaseFormatterText(newRow.therapyClass);
      newRow.reimbursedClassification= titleCaseFormatterText(newRow.reimbursedClassification ?? null);
      // }

      const shouldShowExFactory = this.legendService.shouldShowPrice(newRow.exfactoryColorCode);
      const shouldShowHospital = this.legendService.shouldShowPrice(newRow.hospitalColorCode);
      const shouldShowPpp = this.legendService.shouldShowPrice(newRow.pppColorCode);
      const shouldShowPpExVat = this.legendService.shouldShowPrice(newRow.ppExVatColorCode);
      const shouldShowPpIncVat = this.legendService.shouldShowPrice(newRow.ppIncVatColorCode);

      if (!shouldShowHospital  ) {
        newRow.hospitalPrice = '';
        newRow.hospitalColorCode = '';
      }
      if (!shouldShowExFactory) {
        newRow.exFactoryPrice = '';
        newRow.exfactoryColorCode = '';
      }
      if (!shouldShowPpExVat) {
        newRow.ppExVat = '';
        newRow.ppExVatColorCode = '';
      }
      if (!shouldShowPpIncVat) {
        newRow.ppIncVat = '';
        newRow.ppIncVatColorCode = '';
      }
      if (!shouldShowPpp) {
        newRow.ppp = '';
        newRow.pppColorCode = '';
      }

      if (isShowHistorical) {
        if (newRow.packId === lastPackId && idx % this.pageSize !== 0 && newRow.countryHoverTitle === lastCountryHoverTitle) {
          newRow.samePack = true;
        }
        else if (idx !== 0) {
          this.rowData[idx - 1].lastSamePack = true;
        }
      }

      lastPackId = newRow.packId;
      lastCountryHoverTitle = newRow.countryHoverTitle;
      lastBrandINN=newRow.brandInns;
      lastPackDesc=newRow.packDescription
      this.rowData.push(newRow);
    });
    return this.rowData;
  }

  cotdataload(dataset: any[], checkdate: boolean): any {
    this.rowData = [];
    let index = this.datastoreSelectedColumnCOT$.value.findIndex(x=> x === "availabilityStatus")
    
    //check if prelaunch data exist or not
    const hasNullOrEmptyPackDescription = dataset.some(item => {
      return item.packDescription === null || (item.packDescription && item.packDescription.trim() === '');
    });
    this.isPrelaunchDataExistInCOT$.next(hasNullOrEmptyPackDescription);
    //
    dataset?.forEach((row: any, idx: number) => {
      let newRow = { ...row }
      // if (checkdate) {//when export current page, date columns coming with T. ths removed this condition
      if(index != -1){
        newRow.availabilityStatus = row.packDescription === null ? 'Approved' : 'Available';
      }
      newRow.indicationApprovalDate = setFormat(newRow.indicationApprovalDate, Formatter.DateFormat);
      newRow.regulatoryIndication = titleCaseFormatterText(newRow.regulatoryIndication);

      newRow.frequencyLoadingUnit = this.splitDosingUnit(newRow.frequencyLoading?.trim());
      newRow.frequencyLoading = this.splitDosingNumber(newRow.frequencyLoading?.trim());
      newRow.frequencyMaintenanceUnit = this.splitDosingUnit(newRow.frequencyMaintenance?.trim());
      newRow.frequencyMaintenance = this.splitDosingNumber(newRow.frequencyMaintenance?.trim());
      newRow.durationLoadingUnit = this.splitDosingUnit(newRow.durationLoading?.trim());
      newRow.durationLoading = this.splitDosingNumber(newRow.durationLoading?.trim());
      newRow.durationMaintenanceUnit = this.splitDosingUnit(newRow.durationMaintenance?.trim());
      newRow.durationMaintenance = this.splitDosingNumber(newRow.durationMaintenance?.trim());
      // }
      this.rowData.push(newRow);
    });
    return this.rowData;
  }

  splitDosingNumber(params: any) {
    const value = params?.split(' ')[0]
    if (value)
      return new Number(value).toFixed(2)
    return params
  }

  splitDosingUnit(params: any) {
    if (params) {
      const valData = params?.split(' ')
      if (valData?.length > 1)
        return valData[1]
    }

    return params
  }

  Htadataload(dataset: any[], checkdate: boolean): any {
    this.rowData = [];
    dataset?.forEach((row: any, idx: number) => {
      let newRow = { ...row }
      newRow.indicationApprovalDate = setFormat(newRow.indicationApprovalDate ?? null, Formatter.DateFormat);
      newRow.assessmentPublicationDate = setFormat(newRow.assessmentPublicationDate ?? null, Formatter.DateFormat);
      newRow.indicationHta = titleCaseFormatterText(newRow.indicationHta?? null);
      newRow.clinicalReason = titleCaseFormatterText(newRow.clinicalReason);
      newRow.economicalReason = titleCaseFormatterText(newRow.economicalReason);
      newRow.standardisedDecision = titleCaseFormatterText(newRow.standardisedDecision);
      newRow.utilisationRestrictions = titleCaseFormatterText(newRow.utilisationRestrictions );
      newRow.comparatorDrugHta = titleCaseFormatterText(newRow.comparatorDrugHta);
      newRow.commercialAgreement = titleCaseFormatterText(newRow.commercialAgreement);
      newRow.nationalHtaBody = newRow.nationalHtaBody ? newRow.nationalHtaBody.toUpperCase() : null;
      newRow.regulatoryIndication = titleCaseFormatterText(newRow.regulatoryIndication ?? null);
      newRow.estimatedPatientNumber=titleCaseFormatterText(newRow.estimatedPatientNumber ?? null);
      this.rowData.push(newRow);
    });
    return this.rowData;
  }

  getSelectedGridModeTabKey() {
    switch (this.datastoreTabSelectedIndex$.value) {
      case Number(RequestTypeEnum.PnR):
        return GridViewTypeEnum.packprice;
      case Number(RequestTypeEnum.CoT):
        return GridViewTypeEnum.indicationcot;
      case Number(RequestTypeEnum.HTA):
        return GridViewTypeEnum.hta;
      default:
        return GridViewTypeEnum.packprice;
    }
  }

  get isInsightCompanySearch(): boolean {
    return (this.router.url.startsWith('/insights') && this.route.snapshot.queryParams.t.toLowerCase() === "company");
  }

  get isInsightCompanySearchTableMode(): boolean {
    return (this.isInsightCompanySearch && this.gridModeValue$.value === "table");
  }

  get isInsightCompanySearchChartMode(): boolean {
    return (this.isInsightCompanySearch && this.gridModeValue$.value === "chart");
  }

}
